<template>
  <a-modal
    :visible="visible"
    title="新增調查"
    okText="確認新增"
    cancelText="取消"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <!-- Form -->
    <a-form
      :model="formState"
      layout="vertical"
      autocomplete="off"
    >
      <a-row>
        <a-col :span="24">
          <a-form-item
            label="即時調查名稱"
            name="name"
            :rules="[{ required: true, message: '請輸入即時調查名稱' }]"
          >
            <a-input v-model:value="formState.name" size="large" />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <!-- Form -->
    
    <a-typography-text
      type="secondary"
      :style="{ display: 'block', textAlign: 'right' }"
    >調查問卷可以在創建後進行編輯</a-typography-text>
  </a-modal>
</template>

<script>
import { defineComponent, reactive } from 'vue'
import { message } from 'ant-design-vue'
import api from '@/apis'

export default defineComponent({
  props: ['visible'],
  components: {},
  setup() {
    /* Form State */
    const initialState = {
      name: ''
    }
    const formState = reactive({ ...initialState });

    function resetForm() {
      Object.assign(formState, initialState);
    }

    return {
      /* FormState */
      formState,
      resetForm,

      /* Api */
      api
    }
  },
  methods: {
    async handleOk() {
      try {
        await this.api.v1.course.createOneInstantSurvey(
          this.$route.params.course_id,
          this.formState
        );
        message.success('創建調查成功');
      } catch (error) {
        message.error('創建調查發生錯誤');
        console.log(error);
      }

      this.resetForm();
      this.$emit('confirm');
    },
    async handleCancel() {
      this.$emit('cancel');
      this.resetForm();
    }
  }
})
</script>