<template>
  <!-- Layout Header -->
  <a-layout-header class="layout-header" :style="{ height: device.mobile ? '50px' : '74px' }">
    <a-row>
      <a-col :span="24">
        <div class="letwecare-breadcrumb">
          <a-breadcrumb
            v-if="!device.mobile"
            class="__breadcrumb"
            :routes="breadcrumbRoutes"
          >
            <template #itemRender="{ routes, route }">
              <span v-if="routes.indexOf(route) === routes.length - 1">
                {{ route.breadcrumbName }}
              </span>
              <router-link v-else :to="route.path">
                {{ route.breadcrumbName }}
              </router-link>
            </template>
          </a-breadcrumb>

          <a-page-header
            class="__page-header"
            style="height: 63px; padding-left: 0;"
            :title="`${courseData.name}：即時調查`"
            @back="backToCoursePage"
          >
            <template #itemRender="{ route, params, routes, paths }">
              <span v-if="routes.indexOf(route) === routes.length - 1">{{route.breadcrumbName}}</span>
              <router-link v-else :to="paths.join('/')">{{route.breadcrumbName}}</router-link>
            </template>
          </a-page-header>
        </div>
      </a-col>
    </a-row>
  </a-layout-header>
  <!-- Layout Header -->

  <!-- Layout Body -->
  <a-layout class="app-layout instant-survey-layout">
    <a-card class="instant-survey-card-list">
      <div class="mb-4">
        <a-button type="primary" @click="createInstantSurveyDialog.visible = true">
          <template #icon>
            <PlusOutlined :style="{ color: 'white', fontWeight: 'bold' }" />
          </template>
          新增調查
        </a-button>
      </div>
      
      <a-table
        :columns="columns"
        :data-source="instantSurveysData"
        rowKey="uid"
        :scroll="{ x: 'max-content' }"
      >
        <template #bodyCell="{ column, text, record }">
          <template v-if="column.key === 'contents_count'">
            {{ record.contents.length }}
          </template>

          <template v-if="column.key === 'is_open'">
            <span v-if="record.is_open" :style="{ color: green[5] }">開放</span>
            <span v-else :style="{ color: red[5] }">不開放</span>
          </template>

          <template v-if="column.key === 'is_show_stat'">
            <span v-if="record.is_show_stat" :style="{ color: green[5] }">顯示</span>
            <span v-else :style="{ color: red[5] }">不顯示</span>
          </template>

          <template v-if="column.key === 'action'">
            <a-button @click="toEditorPage(record.id)">前往</a-button>
          </template>
        </template>
      </a-table>
    </a-card>
  </a-layout>
  <!-- Layout Body -->

  <CreateInstantSurveyModal
    :visible="createInstantSurveyDialog.visible"
    @confirm="handleCreateInstantSurveyConfirm"
    @cancel="handleCreateInstantSurveyCancel"
  />
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { message, Empty } from 'ant-design-vue'
import { green, red } from '@ant-design/colors'
import { createDeviceDetector } from 'next-vue-device-detector'
import { PlusOutlined } from '@ant-design/icons-vue'
import { useAuthStore } from '@/store/auth'
import api from '@/apis'
import CreateInstantSurveyModal from './components/CreateInstantSurveyModal.vue'

const columns = [{
  title: '名稱',
  dataIndex: 'name',
  key: 'name'
}, {
  title: '開放填答',
  dataIndex: 'is_open',
  key: 'is_open'
}, {
  title: '題目數',
  dataIndex: 'contents_count',
  key: 'contents_count',
}, {
  title: '建立日期',
  dataIndex: 'created_datetime',
  key: 'created_datetime',
}, {
  title: '操作',
  key: 'action'
}]


export default defineComponent({
  components: {
    PlusOutlined,
    CreateInstantSurveyModal
  },
  setup() {
    const route = useRoute();
    const device = createDeviceDetector();

    /* Store */
    const authStore = useAuthStore();

    /* Loading */
    const loading = ref(false);

    /* Data */
    const courseData = ref({});
    const instantSurveysData = ref([]);

    /* Api */
    const getCourseData = (async () => {
      const response = await api.v1.course.findOne(route.params.course_id);
      courseData.value = response.data.result;
      console.log(courseData.value);
    });

    const getInstantSurveysData = (async () => {
      const response = await api.v1.course.instantSurveyList(route.params.course_id);
      instantSurveysData.value = response.data.result;
      console.log(instantSurveysData.value)
    });

    /* Routes breadcrumb */
    const breadcrumbRoutes = ref([
      {
        path: '/app/courses',
        breadcrumbName: '班級管理'
      }
    ]);

    /* Mounted */
    onMounted(async () => {
      loading.value = true;

      await getCourseData();
      await getInstantSurveysData();

      // set routes breadcrumb
      breadcrumbRoutes.value.push({
        path: `/app/course/${ route.params.course_id }`,
        breadcrumbName: courseData.value.name
      });
      breadcrumbRoutes.value.push({
        path: `/app/course/${ route.params.course_id }/instant-survey`,
        breadcrumbName: '即時調查'
      });

      loading.value = false;
    });

    return {
      device,

      /* Store */
      authStore,

      /* Routes breadcrumb */
      breadcrumbRoutes,

      /* Loading */
      loading,

      /* Data */
      columns,
      courseData,
      instantSurveysData,

      /* Api */
      getInstantSurveysData,

      /* Antd */
      message,
      green,
      red
    }
  },
  data() {
    return {
      /* Create Instant Survey Dialog */
      createInstantSurveyDialog: {
        visible: false
      }
    }
  },
  methods: {
    /* Router */
    backToCoursePage() {
      this.$router.push({
        name: 'AppCoursePage',
        params: {
          course_id: this.$route.params.course_id
        }
      });
    },
    toEditorPage(id) {
      this.$router.push({
        name: 'AppCourseInstantSurveyIdPageEditor',
        params: {
          course_id: this.$route.params.course_id,
          id: id
        }
      })
    },

    /* Create Instant Survey Dialog */
    handleCreateInstantSurveyConfirm() {
      this.createInstantSurveyDialog.visible = false;
      this.getInstantSurveysData();
    },
    handleCreateInstantSurveyCancel() {
      this.createInstantSurveyDialog.visible = false;
    }
  }
})
</script>

<style lang="scss">
.instant-survey-layout {
  .instant-survey-card-list {
    width: 100%;
    max-width: 850px;
    margin: 0px auto;

    /* md */
    @media screen and (min-width: 970px) {
      margin: 20px auto;
    }

    .survey-card {
      border-radius: 4px;
      border: 0;
      box-shadow: 0 2px 4px 0 hsl(0deg 0% 87% / 50%);
      margin-bottom: 12px !important;

      .ant-card-body {
        padding: 12px !important;
      }

      /* md */
      @media screen and (min-width: 970px) {
        margin-bottom: 24px !important;

        .ant-card-body {
          padding: 24px !important;
        }
      }
    }
  }
}
</style>